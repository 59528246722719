import useWebViewChecker from '../useWebViewChecker';
import useArticleType from './useArticleType';
import useIsLabel from './useIsLabel';
import usePrint from './usePrint';

// Dont request for rainbow content on the following article-types
const excludedArticleTypes = [ 'conferenceArticle', ];

export default function useIsRainbowEnabled() {
  const isWebView = useWebViewChecker();
  const print = usePrint();
  const isLabel = useIsLabel();
  const articleType = useArticleType();

  return !(isWebView || print.isPrint || isLabel || excludedArticleTypes.includes(articleType));
}
