// @flow
/* global sessionStorage Event window */

const SESSION_START_KEY = 'sessionStart';
const SESSION_START_TIME_KEY = 'sessionStartTime';
let sessionStartEventHandlers = [];
/**
 * test if a new session has just created
 * @returns {boolean} true if new session has just created
 */
function isNewSession(): boolean {
  const sessionStatus = sessionStorage.getItem(SESSION_START_KEY);
  return !sessionStatus || sessionStatus === 'justCreated';
}

/**
 * inititilize a session if a session not already exists
 */
function startSession() {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  const sessionStatus = sessionStorage.getItem(SESSION_START_KEY);

  if (!sessionStatus) {
    sessionStorage.setItem(SESSION_START_KEY, 'justCreated');
    sessionStorage.setItem(SESSION_START_TIME_KEY, `${Date.now()}`);
  }
  else if (sessionStatus === 'justCreated') {
    sessionStorage.setItem(SESSION_START_KEY, 'started');
  }

  fire();
}

function getSessionDetails() {
  const createdVal = sessionStorage.getItem(SESSION_START_TIME_KEY);
  const createdTime = isNaN(createdVal) ? null : parseInt(createdVal);

  return {
    status: sessionStorage.getItem(SESSION_START_KEY),
    created: createdTime,
  };
}

/**
 * Add event listener for session events
 * @param {string} eventType
 * @param {function} eventHandler
 */
function addEventListener(eventType: string, eventHandler: Event => void) {
  if (eventHandler && typeof eventHandler === 'function') {
    switch (eventType) {
      case 'sessionstart':
        sessionStartEventHandlers.push(eventHandler);
        fire();
        break;
      default:
        break;
    }
  }
  else {
    throw new Error('eventHandler must be a function');
  }
}

/**
 * Removes event listener
 * @param {string} eventType
 * @param {function} eventHandler
 */
function removeEventListener(eventType: string, eventHandler: Event => void) {
  switch (eventType) {
    case 'sessionstart':
      sessionStartEventHandlers = sessionStartEventHandlers.filter(item => item !== eventHandler);
      break;
    default:
      break;
  }
}

/**
 * Triggers 'sessionstart' event if session has started
 */
function fire() {
  const isNewSessionNow = isNewSession();

  if (isNewSessionNow) {
    const event = new Event('sessionstart', getSessionDetails());

    while (sessionStartEventHandlers.length > 0) {
      const eventHandler = sessionStartEventHandlers.shift();
      eventHandler(event);
    }
  }
}

const SessionListener = {
  addEventListener,
  removeEventListener,
  startSession,
  get sessionDetails() {
    return getSessionDetails();
  },
};

/**
 * Initialize session on page-load
 */
if (typeof window !== 'undefined') {
  window.addEventListener('DOMContentLoaded', startSession);
}

export default SessionListener;
