/* eslint-disable no-shadow */
/* global window document location localStorage */
// @flow
import * as React from 'react';
import { useApolloClient, } from '@apollo/react-hooks';
import { useRouter, } from 'next/router';
import { GET_MARKETING_DATA_QUERY, GET_DEFAULT_MARKETING_DATA_QUERY, } from '@haaretz/graphql';
import useFactsProvider from './utils/useRainbowFactsProvider';
import useIsRainbowEnabled from '../../hooks/Page/useIsRainbowEnabled';
import { useUser, } from '../User/UserDispenser';
import { useIsBot, } from '../../hooks/useIsBot';

function isItemOfSlot(slotId) {
  return item => item.slotId === slotId;
}

// RainbowTools context is cunstructed of [ [ tools ], [ slotIds ], isLoaded ]
export const RainbowToolsContext = React.createContext([
  [], // Empty tools array
  new Set(), // Empry slot ids
  false, // flag indicates Rainbow request has completed
]);

type PropsType = {
  children: ChildrenArray<any>,
};

/**
 * Ask to fetch rainbow-tool for given slot-id.
 * @param {string} slotId
 * @returns rainbow-tool associated with slot-id
 */
export function useRainbowSlot(slotId: string) {
  const [ tools, slots, ] = React.useContext(RainbowToolsContext);
  let slotTool;

  if (!slots.has(slotId)) {
    slots.add(slotId);
  }
  else {
    slotTool = tools.find(isItemOfSlot(slotId));
  }

  return slotTool;
}

/**
 * Retrive the tool accoieted with given slot-id(s), but not fetches them from the server.
 * @param {string | Array<string>} slotIds
 * @returns {[ Array<any>, boolean ]} a touple of rainbow-tools array associated with given slot-ids and boolean indicates the Rainbow request has completed.
 */
export function useNoFetchRainbowSlot(slotIds: string | Array<string>) {
  const [ tools, slots, isLoaded, ] = React.useContext(RainbowToolsContext);
  const foundTools = [];
  const innerSlotIds = Array.isArray(slotIds)
    ? slotIds
    : [ slotIds, ];

  for (const slotId of innerSlotIds) {
    if (slots.has(slotId)) {
      const tool = tools.find(isItemOfSlot(slotId));

      if (tool) {
        foundTools.push(tool);
      }
    }
  }

  return [ foundTools, isLoaded, ];
}

export default function RainbowDataProvider({ children, }: PropsType) {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const factsProvider = useFactsProvider();
  const isRainbowEnabled = useIsRainbowEnabled();
  const { user, } = useUser();
  const { isBot, } = useIsBot();

  const path = router?.query?.path;
  const slots = React.useRef(new Set()); // The slots defined in this Provider scope.

  const [ tools, setTools, ] = React.useState([]);
  const [ isLoaded, setIsLoaded, ] = React.useState(false);

  const useOnlyDefaultToolsForAnonymousPromise = React.useMemo(() => {
    let value = null;

    if (path) {
      value = queryUseOnlyDefaultToolsForAnonymous(apolloClient, path);
    }

    return value;
  }, [ apolloClient, path, ]);

  // Fetch default rainbow tools for anonymous users
  const defualtToolsPromise = React.useMemo(() => {
    let promise = null;

    if (path) {
      promise = queryDefaultMarketingTools(apolloClient, path);
    }

    return promise;
  }, [ apolloClient, path, ]);

  // Execute facts collecting ASAP for accelerating Rainbow response.
  const factsPromise = React.useMemo(() => {
    let promise = null;
    if (typeof window !== 'undefined') {
      if (!isBot && isRainbowEnabled && user.type) {
        promise = factsProvider();
      }
    }

    return promise;
  }, [ factsProvider, isBot, isRainbowEnabled, user.type, ]);

  /**
   * For Anonymous user only, use the default rainbow element.
   */
  React.useEffect(() => {
    if (!isBot && isRainbowEnabled && slots.current.size > 0 && user.type && user.type === 'anonymous' && defualtToolsPromise) {
      defualtToolsPromise.then(tools => {
        setTools(tools);
      });
    }
  }, [ defualtToolsPromise, isBot, isRainbowEnabled, user.type, ]);

  /**
   * Query Rainbow for banners.
   * for anonymous users the following behavior is relevant:
   * 1. Rainbow reads the default tools and the 'use only defaults for anonymous' checkbox (in Brightspot).
   * 2. If the checkbox is on, Rainbow uses the default tool when available and  ignores the response for the Rainbow request.
   * 3. If the checkbox is off, Rainbow uses the default tool first, and overrides it with a tool from the Rainbow request.
   */
  React.useEffect(() => {
    if (!isBot && isRainbowEnabled && slots.current.size > 0 && user.anonymousId && user.type && factsPromise) {
      factsPromise
        .then(facts => queryMarketingTools(apolloClient, facts, slots.current))
        .then(fetchedTools => Promise.all([ useOnlyDefaultToolsForAnonymousPromise, defualtToolsPromise, Promise.resolve(fetchedTools), ]))
        .then(([ forceDefaults, defaultTools, fetchedTools, ]) => {
          let toolsToRender = null;
          if (forceDefaults) {
            toolsToRender = mergeTools(fetchedTools, defaultTools);
          }
          else {
            toolsToRender = fetchedTools;
          }

          return Promise.resolve(toolsToRender);
        })
        .then(tools => {
          setTools(tools);
          setIsLoaded(true);
        });
    }
  }, [ apolloClient, isRainbowEnabled, user.type, isBot, factsPromise, useOnlyDefaultToolsForAnonymousPromise, defualtToolsPromise, user.anonymousId, ]);

  return (
    <RainbowToolsContext.Provider value={[ tools, slots.current, isLoaded, ]}>
      {children}
    </RainbowToolsContext.Provider>
  );
}

async function queryMarketingTools(apolloClient, facts, slots) {
  const { data, } = await apolloClient.query({
    query: GET_MARKETING_DATA_QUERY,
    variables: {
      facts: JSON.stringify(facts, nullReplacer),
      slots: [ ...slots, ],
    },
  });

  return data.Rainbow?.tools || [];
}

async function queryDefaultMarketingTools(apolloClient, path) {
  const { data, } = await apolloClient.query({
    query: GET_DEFAULT_MARKETING_DATA_QUERY,
    variables: {
      path,
    },
  });

  return data.Page?.defaultRainbowTools || [];
}

async function queryUseOnlyDefaultToolsForAnonymous(apolloClient, path) {
  const { data, } = await apolloClient.query({
    query: GET_DEFAULT_MARKETING_DATA_QUERY,
    variables: {
      path,
    },
  });

  return data.Page?.useDefaultOnlyForAnonymous || false;
}

// Merges original array with overrides array
// the overrides items will override the originals items
function mergeTools(original, overrides) {
  const toolsMap = new Map();

  for (const tool of original) {
    toolsMap.set(tool.slotId, tool);
  }

  for (const tool of overrides) {
    toolsMap.set(tool.slotId, tool);
  }

  return Array.from(toolsMap.values());
}

function nullReplacer(key, value) {
  return value === null || key === '__typename' ? undefined : value;
}
